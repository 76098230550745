<template>
	<page v-show="userIdentifier">
		<communication-preference-modal v-if="customerLoaded && acquireCommunicationPreference && !communicationPreferenceAcquired" />
		<information-modal />
		<div class="container">
			<div class="insurance-table">
				<welcome v-if="customer.loaded" class="welcome">
					<template #goodmorning>Goedemorgen</template>
					<template #goodafternoon>Goedemiddag</template>
					<template #goodevening>Goedenavond</template>
					<template #goodnight>Goedenacht</template>
					<template>{{ customer.nameForDisplay }}</template>
				</welcome>
				<div v-if="policiesLoaded && claimsLoaded && !customerOnlyHasMoneyMaxxProducts">
					<span v-if="policiesInNeedOfAttention.length">
						<notice v-for="(item, index) in policiesInNeedOfAttention" :key="index" :class="{ 'first-notice': index === 0 }" role="alert" aria-live="assertive" tabindex="0">
							<template v-if="isExpiring(item, 6)">
								<span v-if="isLifeCourseSavingsScheme(item)"><strong>De levensloopregeling eindigt</strong><br>De levensloopregeling eindigt op 1 november 2021. Het tot deze datum opgebouwde tegoed wordt dan belast. Dit betekent dat a.s.r. over dit tegoed loonheffing inhoudt en afdraagt aan de Belastingdienst. U kunt daarna vrij beschikken (zonder tussenkomst werkgever) over uw resterende tegoed. <a href="#" role="button" aria-label="regel nu online uw uitkering" @click.prevent="startExpiration(item, $event)">Regel nu online uw uitkering</a>, de uitkering wordt dan begin november rechtstreeks aan u overgemaakt.</span>
								<span v-else>Uw polis met nummer {{ item.policyIdentifier }} bereikt binnenkort de einddatum. <a href="#" role="button" aria-label="regel nu online uw uitkering" @click.prevent="startExpiration(item, $event)">Regel nu online uw uitkering.</a></span>
							</template>
							<template v-if="hasOpenAdvisorClaim(item)">
								Er staat een uitkeringsverzoek voor polis {{ item.policyIdentifier }} klaar. Voor het afronden is uw akkoord nog nodig. <a href="#" role="button" aria-label="regel uw akkoord online" @click.prevent="completeClaim(item, $event)">Regel het online.</a>
							</template>
							<template v-if="hasOpenSystemExpirationClaim(item)">
								Uw polis met nummer {{ item.policyIdentifier }} heeft de einddatum bereikt. <a href="#" role="button" aria-label="regel nu online uw uitkering" @click.prevent="completeClaim(item, $event)">Regel nu online uw uitkering.</a>
							</template>
							<template v-if="isPending(item)">Voor het afronden van uw uitkeringsverzoek voor polis {{ item.policyIdentifier }} hebben we nog documenten nodig. <a href="#" role="button" aria-label="lever uw documenten online aan" @click.prevent="policyDocuments(item, $event)">Regel het online.</a></template>
						</notice>
					</span>
					<span v-if="packagePoliciesToRedeem.length">
						<notice v-for="(item, index) in packagePoliciesToRedeem" :key="index" :class="{ 'first-notice': policiesInNeedOfAttention.length === 0 && index === 0 }" role="alert" aria-live="assertive" tabindex="0">
							<template>U heeft één verzekering in een pakket stopgezet. Zet vandaag ook de andere verzekering(en) uit uw pakket stop. <a href="#" role="button" aria-label="regel hier het stopzetten van uw verzekering" @click.prevent="startCommute(item, $event)">Regel hier het stopzetten van uw verzekering.</a></template>
						</notice>
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-9 col-lg-9">
					<div v-if="messages && messages.length" class="content--card">
						<h2 class="insurance-table__heading">Actueel</h2>
						<accordion-list class="accordion-list--chevron">
							<accordion-item v-for="message in messages" :id="message.id" :key="message.id" :single="true"
								:clicked="clickedMessage" @toggle="toggleMessage">
								<template #title>
									{{ message.title }}
									<span class="accordion__show">toon</span>
									<span class="accordion__hide">verberg</span>
								</template>
								<div v-html="message.text"></div>
							</accordion-item>
						</accordion-list>
					</div>
					<request-loader 
						:is-loading="!policiesLoaded || !claimsLoaded" 
						loading-message="Uw polissen worden geladen" 
						finish-message="Uw polissen zijn geladen" 
					/>
					<div v-if="policiesLoaded && claimsLoaded" class="content--card">
						<h2 class="insurance-table__heading">Uw levensverzekeringen</h2>
						<div 
							v-if="policies.length" 
							class="insurance-table__content" 
							role="table"
							aria-label="Uw levensverzekeringen"
							:aria-rowcount="policies.length"
						>
							<div class="rowgroup">
								<div class="insurance-table__row insurance-table__row--heading row" role="row">
									<div id="table-header-product" class="row__item col-lg-5 col-md-5" role="columnheader">Product</div>
									<div id="table-header-policynumber" class="row__item col-lg-4 col-md-4" role="columnheader">Pakket / Polisnummer</div>
									<div id="table-header-status" class="row__item col-lg-3 col-md-3" role="columnheader">Status</div>
								</div>
							</div>
							<accordion-list class="accordion-list--chevron" role="rowgroup">
								<accordion-item 
									v-for="(item, index) in policies" 
									:id="`policy_${index}`" 
									:key="`policy_${index}`"
									:single="true" 
									:opened="checkOnePolicy()"
									:clicked="clickedPolicy"
									role="row"
									:aria-label="`product: ${item.product}; ${item.packageNumber ? `pakketnummer: ${item.packageNumber}` : ''}; polisnummer: ${item.policyIdentifier}; status: ${item.status}`"
									@toggle="(open) => togglePolicy(open, `policy_${index}`)"
								>
									<template #title>
										<div class="insurance-table__row row">
											<div class="row__item col-lg-5 col-md-5 col-xs-12 mobile__hide">
												<p class="item__value word__break">
													{{ item.product }}
												</p>
											</div>
											<div class="row__item col-lg-4 col-md-4 col-xs-12">
												<p class="item__value"><span class="mobile__hide">{{ item.packageNumber || "n.v.t." }} /</span>
													<span class="mobile__show">{{ item.product }} / </span> {{ item.policyIdentifier }}
												</p>
											</div>
											<div class="row__item col-lg-3 col-md-3 col-xs-12 mobile__hide">
												<div>
													<p class="item__value">{{ item.status }}</p>
													<span class="accordion__show">acties</span>
												</div>
											</div>
										</div>
									</template>
									<div class="policy--buttons">
										<div class="insurance-table__row row mobile__show">
											<div class="row__item col-lg-4 col-md-6 col-xs-12">
												<p class="item__title">Product</p>
												<p class="item__value word__break">
													{{ item.product }}
												</p>
											</div>
											<div class="row__item col-lg-3 col-md-3 col-xs-12">
												<p class="item__title">Pakket / Polisnummer</p>
												<p class="item__value">{{ item.packageNumber || "n.v.t." }} / {{ item.policyIdentifier }}</p>
											</div>
											<div class="row__item col-lg-4 col-md-3 col-xs-12">
												<p class="item__title">Status</p>
												<div>
													<p class="item__value">{{ item.status | status }}</p>
												</div>
											</div>
										</div>
										<a href="#" @click.prevent="detailPolicy(item, $event)">Bekijk polis gegevens</a>
										<span v-if="!isMoneyMaxxPolicy(item) && !isPolicyEndedNoClaimYet(item)
											&& (isRedeemable(item)
											|| isExpiring(item, 9)
											|| hasOpenSystemExpirationClaim(item)
											|| hasOpenAdvisorClaim(item)
											|| isPending(item))">
											<span v-if="!item.currentUserIsCoPolicyHolder">
												<span v-if="!hasOpenOrPendingExpirationClaim(item) && !hasExpiredPackageAccrualPolicy(item)">
													<a href="#" v-if="hasOpenAdvisorClaim(item)" @click.prevent="completeClaim(item, $event)">Voortijdig stopzetten</a>
													<a href="#" v-else-if="hasPendingRedemptionClaim(item)" @click.prevent="policyDocuments(item, $event)">Voortijdig stopzetten</a>
													<a href="#" v-else-if="isRedeemable(item)" @click.prevent="startCommute(item, $event)">Voortijdig stopzetten</a>
												</span>
												
												<span v-if="!hasOpenOrPendingRedemptionClaim(item)">
													<a href="#" v-if="hasOpenSystemExpirationClaim(item) || hasOpenAdvisorClaim(item)" @click.prevent="completeClaim(item, $event)">Uitkeren op einddatum</a>
													<a href="#" v-else-if="hasPendingExpirationClaim(item)" @click.prevent="policyDocuments(item, $event)">Uitkeren op einddatum</a>
													<a href="#" v-else-if="canExpire(item)" @click.prevent="startExpiration(item, $event)">Uitkeren op einddatum</a>
												</span>
											</span>
											<span v-else>
												<a href="#" @click.prevent="showCoPolicyHolderMessage = true">Uw verzekering uitkeren</a>
											</span>
										</span>
										<a href="#" v-if="changePolicyIsAllowed(item)" @click.prevent="mutatePolicy(item, $event)">Uw verzekering wijzigen</a>
										<p v-if="showCoPolicyHolderMessage">
											<strong>Helaas, u heeft geen toegang tot "Uw verzekering uitkeren"</strong><br>
											U staat namelijk als 2e verzekeringnemer vermeld op de polis. Alleen de 1e verzekeringnemer {{ item.policyHolderFullName }} kan via het a.s.r. account de verzekering laten uitkeren.
										</p>
									</div>
								</accordion-item>
							</accordion-list>
						</div>
					</div>

					<div v-if="policiesLoaded && claimsLoaded && !customerOnlyHasMoneyMaxxProducts" class="content--card">
						<h2 v-if="currentUserCoPolicyHolderPolicies" class="insurance-table__heading">
							<information-link
								id="uitkeringsverzoeken"
								label="Uw uitkeringsverzoeken"
								text="Dit overzicht is mogelijk niet compleet. Hier verschijnen alleen uitkeringsverzoeken voor de polis(sen) waarop u als 1e verzekeringnemer staat vermeld. Een uitkeringsverzoek voor een polis waarop u de 2e verzekeringnemer bent, verschijnt hier niet."
							/>
						</h2>
						<h2 v-else class="insurance-table__heading">Uw uitkeringsverzoeken</h2>
						<div 
							v-if="sortedClaims.length"
							class="insurance-table__content" 
							role="table"
							aria-label="Uw uitkeringsverzoeken"
							:aria-rowcount="sortedClaims.length"
						>
							<div class="rowgroup">
								<div class="insurance-table__row insurance-table__row--heading row" role="row">
									<div class="row__item col-lg-3 col-md-4" role="columnheader">Soort verzoek</div>
									<div class="row__item col-lg-4 col-md-4" role="columnheader">Pakket / Polisnummer</div>
									<div class="row__item col-lg-4 col-md-4" role="columnheader">Status verzoek</div>
								</div>
							</div>
							<accordion-list class="accordion-list--chevron" role="rowgroup">
								<accordion-item 
									v-for="(claim, index) in sortedClaims" 
									:id="`claim_${index}`" 
									:key="`claim_${index}`" 
									:single="true" 
									:opened="checkOneClaim()"
									:clicked="clickedClaim" @toggle="toggleClaim(`claim_${index}`)"
									role="row"
									:aria-label="
										`soort verzoek: ${claimCause(claim.listOfMyPolicies[0].damageCause)};
										claimnummer: ${claim.claimNumber};
										${claim.listOfMyPolicies[0].packagePolicyIdentifier ? `pakketnummer: ${claim.listOfMyPolicies[0].packagePolicyIdentifier}` : ''};
										polisnummer: ${cleanPolicyNumber(claim.listOfMyPolicies[0].policyIdentifier)}; status: ${claim.externalStatusName}`
									"
								>
									<template #title>
										<div class="insurance-table__row row">
											<div class="row__item col-lg-3 col-md-4 col-xs-12">
												<p class="item__value word__break">
													{{ claimCause(claim.listOfMyPolicies[0].damageCause) }}
													<span class="item__subtext">claimnr: {{ claim.claimNumber }}</span>
												</p>
											</div>
											<div class="row__item col-lg-4 col-md-4 col-xs-12 mobile__hide">
												<p class="item__value">{{ claim.listOfMyPolicies[0].packagePolicyIdentifier || "n.v.t." }} / {{ cleanPolicyNumber(claim.listOfMyPolicies[0].policyIdentifier) }}
													<span class="item__subtext">&nbsp;</span>
												</p>
											</div>
											<div class="row__item col-lg-4 col-md-4 col-xs-12 mobile__hide">
												<div>
													<p class="item__value word__break">
														{{ claim.externalStatusName }}
														<span class="item__subtext">&nbsp;</span>
													</p>
													<span class="accordion__show">acties</span>
												</div>
											</div>
										</div>
									</template>
									<div class="policy--buttons">
										<div class="insurance-table__row row mobile__show">
											<div class="row__item col-lg-4 col-md-4 col-xs-12">
												<p class="item__title">Soort verzoek</p>
												<p class="item__value word__break">
													{{ claimCause(claim.listOfMyPolicies[0].damageCause) }}
													<span class="item__subtext">claimnr: {{ claim.claimNumber }}</span>
												</p>
											</div>
											<div class="row__item col-lg-3 col-md-4 col-xs-12 ">
												<p class="item__title">Pakket / Polisnummer</p>
												<p class="item__value">{{ claim.listOfMyPolicies[0].packagePolicyIdentifier || "n.v.t." }} / {{ cleanPolicyNumber(claim.listOfMyPolicies[0].policyIdentifier) }} </p>
											</div>
											<div class="row__item col-lg-3 col-md-4 col-xs-12">
												<p class="item__title">Status</p>
												<div>
													<p class="item__value word__break">{{ claim.externalStatusName }}</p>
												</div>																							</div>
										</div>
										<a href="#" @click.prevent="detailClaim(claim, $event)">{{ claim.statusId === -4 ? "Bekijk historie" : "Bekijk status" }}</a>
									</div>
								</accordion-item>
							</accordion-list>
						</div>
						<div class="insurance-table__content" v-else>
							<p class="no-requests">U heeft nog geen uitkeringsverzoeken.</p>
						</div>
					</div>

					<div class="content--card">
						<h2 class="insurance-table__heading">a.s.r. account</h2>
						<div class="insurance-table__content">
							<p class="account-info">Bekijk al uw a.s.r. producten en beheer uw inloggegevens<br>
								<protected-button :protect="impersonation" childClass="link-account--icon" title="Naar uw a.s.r. account">
									<template #button>
										<a href="#" @click.prevent="goToMyAccount" class="link-account--icon">Naar uw a.s.r. account</a>
									</template>
								</protected-button>
							</p>
						</div>
					</div>
				</div>

				<div class="col-xs-12 col-md-3 col-lg-3 side--bar">
					<card v-if="!customerOnlyHasMoneyMaxxProducts && generalFaqs && generalFaqs.length" class="card--side-bar card--withaccordion">
						<template #title>Top 3 vragen</template>
						<accordion-list class="accordion-list--chevron">
							<accordion-item v-for="faq in generalFaqs.slice(0, 3)" :id="faq.id" :key="faq.id" :single="true"
								:clicked="clickedFAQ" @toggle="(open) => toggleFAQ(open, faq.title, faq.id)">
								<template #title>
									{{ faq.title }}
								</template>
								<div v-html="faq.text"></div>
							</accordion-item>
						</accordion-list>
					</card>
					<a class="side--bar-link" v-if="policiesLoaded && !customerOnlyHasMoneyMaxxProducts && generalFaqs && generalFaqs.length" href="veelgesteldevragen.html" @click="toFAQPage">Bekijk alle veelgestelde vragen</a>

					<card class="card--side-bar card--secondary">
						<template #title>Uw correspondentie</template>
						<request-loader 
							:is-loading="!customerLoaded
										|| !policyLoaded
										|| !claimLoaded"
							loading-message="Uw correspondentie wordt geladen"
							finish-message="Uw correspondentie is geladen"
						/>
						<span v-if="customerLoaded && policyLoaded && claimLoaded">
							<span v-if="documents && documents.length">
								<message v-for="document in documents.slice(0, 1)" @click.prevent="documentDetail(document, $event)" :key="document.externalIdentifier">
										<template #date>
											{{ document.sendDate | shortDate }}
										</template>
										<template #content>
											<span v-html="document.subject"></span>
										</template>
								</message>
							</span>
							<p v-else>
								U heeft nog geen correspondentie.
							</p>
						</span>
					</card>
					<a class="side--bar-link" v-if="documents && documents.length > 1" href="communicationdetail.html" @click="toDocumentsPage">Bekijk al uw correspondentie</a>

					<card class="card--side-bar card--secondary">
						<template #title>Service</template>
						<p>Wilt u contact met uw adviseur of met a.s.r.? <a href="contact.html" @click="toContactPage">Ga dan naar de contactpagina</a></p>
						<p>Wilt u uw persoonlijke gegevens wijzigen? Ga dan naar <a href="#" @click.prevent="toProfilePage">uw profiel pagina</a></p>
					</card>

				</div>
			</div>
		</div>
	</page>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { State, namespace } from "vuex-class"
	import { Watch } from "vue-property-decorator"

	import { StoreAction, StoreMutation } from "lib/types/vuex"
	import SecuredMaintenance from "api/security/SecuredMaintenance"
	import { session } from "api/request"
	import diff, { Diff } from "lib/date/diff"

	import { CommunicationDocument, PollClaim } from "store/communication/types"
	import { Policy, Claim } from "store/policies/types"
	import { CustomerState } from "store/customer/types"
	import { Message as MessageInterface, FAQ } from "api/models/communications"

	import Page from "custom/Page.vue"
	import Card from "components/panel/Card.vue"
	import Message from "components/widget/Message.vue"
	import Notice from "components/supplemental/Notice.vue"
	import AccordionList from "custom/AccordionList.vue"
	import AccordionItem from "custom/AccordionItem.vue"
	import RequestLoader from "custom/RequestLoader.vue"
	import InformationLink from "custom/InformationLink.vue"
	import InformationModal from "leven-shared-components/InformationModal.vue"
	import CommunicationPreferenceModal from "custom/modal/CommunicationPreferenceModal.vue"
	import Welcome from "custom/Welcome.vue"

	import * as rules from "store/rules"

	import eventBus from "lib/vue/eventBus"

	import { myAccountUrl } from "api/settings"
	import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
	import { AnnuityExpirationDestination } from "store/routing/types"
	import { tealiumInteractionClickableLink, tealiumInteractionOtherAccordion } from "tracking/events_v4"

	const policies = namespace("policies")
	const communication = namespace("communication")
	const routing = namespace("routing")

	@SecuredMaintenance(session)
	@Component({
		components: {
			Page,
			ProtectedButton,
			Card,
			Message,
			Notice,
			AccordionList,
			AccordionItem,
			RequestLoader,
			Welcome,
			InformationLink,
			InformationModal,
			CommunicationPreferenceModal
		}
	})
	export default class Index extends Vue {
		@State userIdentifier?: string
		@State customer!: CustomerState
		@State impersonation!: boolean
		@communication.Getter documents!: Array<CommunicationDocument>
		@routing.Mutation selectDocumentId!: StoreMutation<string>
		@routing.Mutation selectWidget!: StoreMutation<"addAfkoop" | "addExpiratie" | "complete" | undefined>
		@routing.Mutation selectPolicy!: StoreMutation<Policy | undefined>
		@routing.Mutation selectClaim!: StoreMutation<Claim | undefined>
		@routing.Mutation selectProfileItem!: StoreMutation<string | undefined>
		@routing.Mutation initChangeCommunicationPreference!: StoreMutation<boolean>
		@routing.Mutation setCommunicationPreferenceAcquired!: StoreMutation<boolean>
		@routing.Mutation setPayoutToPledgeHolderRequested!: StoreMutation<boolean>
		@routing.Mutation setAnnuityExpirationDestination!: StoreMutation<AnnuityExpirationDestination>
		@routing.State communicationPreferenceAcquired!: boolean
		@communication.State customerLoaded!: boolean
		@communication.State policyLoaded!: boolean
		@communication.State claimLoaded!: boolean
		@communication.State pollClaim!: PollClaim
		@communication.State messages!: Array<MessageInterface>
		@communication.State generalFaqs!: Array<FAQ>
		@policies.Action pollClaimCommunication!: StoreAction<PollClaim>
		@policies.State policies!: Array<Policy>
		@policies.State claims!: Array<Claim>
		@policies.State policiesLoaded!: boolean
		@policies.State claimsLoaded!: boolean
		@policies.Getter currentUserCoPolicyHolderPolicies: boolean | undefined

		myAccountUrl = myAccountUrl
		isMoneyMaxxPolicy = rules.isMoneyMaxxPolicy

		showCoPolicyHolderMessage = false
		clickedPolicy = ""
		clickedClaim = ""
		clickedFAQ = ""
		clickedMessage = ""
		isEditing = true

		tealiumTitle = "Dashboard"

		get sortedClaims() {
			return [...this.claims].sort((a, b) => a.claimNumber > b.claimNumber ? -1 : 1)
		}

		get policiesInNeedOfAttention() {
			const policyInNeedOfAttention = (policy: Policy): boolean => !policy.currentUserIsCoPolicyHolder && !rules.isMoneyMaxxPolicy(policy)
				? this.hasOpenOrPendingRedemptionClaim(policy)
					? true
					: this.hasOpenSystemExpirationClaim(policy) || this.isExpiring(policy, 6) || this.isPending(policy)
						|| (rules.hasExpirationClaim(policy) && this.hasOpenAdvisorClaim(policy)) && !this.isPledgedPolicy(policy)
				: false

			return this.policies.filter(policyInNeedOfAttention)
		}

		get acquireCommunicationPreference() {
			const today = new Date()
			today.setHours(0, 0, 0, 0)
			const changeDate = this.customer.communicationPreferenceChangeDate || new Date()
			changeDate.setHours(0, 0, 0, 0)
			const daysSinceLastChange = diff(Diff.DAYS, changeDate, today)
			const THREE_MONTHS_AGO = 90

			return this.customer.onlineCommunication || this.communicationPreferenceAcquired ? false :
			 this.customer.previousLogin === undefined || daysSinceLastChange > THREE_MONTHS_AGO
		}

		get packagePoliciesToRedeem() {
			const isPackageRiskPolicyToRedeem = (policy: Policy): boolean => !policy.currentUserIsCoPolicyHolder
				? this.isPackageRiskPolicyToRedeem(policy)
				: false

			return this.policies.filter(isPackageRiskPolicyToRedeem)
		}

		get customerOnlyHasMoneyMaxxProducts() {
			return rules.customerOnlyHasMoneyMaxxProducts(this.policies)
		}

		get tealiumPageInfo() {
			return { virtualPage: { name: this.tealiumTitle } }
		}

		togglePolicy(open: boolean, clickedPolicy: string) {
			this.showCoPolicyHolderMessage = false
			this.clickedPolicy = clickedPolicy
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, "uw levensverzekeringen")
		}

		toggleClaim(clickedClaim: string) {
			this.clickedClaim = clickedClaim
		}

		toggleFAQ(open: boolean, title: FAQ["title"], clickedFAQ: string) {
			this.clickedFAQ = clickedFAQ
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, title)
		}

		toFAQPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		toggleMessage(open: boolean) {
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, "actueel")
		}

		toContactPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		toProfilePage(event: MouseEvent) {
			this.initChangeCommunicationPreference(false)
			this.selectProfileItem("Basisgegevens")

			const targetLink = "profile.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		detailPolicy(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = "policydetail.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		mutatePolicy(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = "mutatieform.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		detailClaim(claim: Claim, event: MouseEvent) {
			this.selectClaim(claim)

			if (claim.hasBeenCancelled) {
				const targetLink = "statusoverview.html"
				tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

				location.href = targetLink

			} else {
				const isExcludedClaim: boolean = claim.listOfMyPolicies[0].damageCause.toLowerCase() === "overlijden"
				const policyByClaim = this.policies.find(policy =>
					policy.policyIdentifier === this.cleanPolicyNumber(claim.listOfMyPolicies[0].policyIdentifier)
				)

				if (policyByClaim && (this.hasOpenSystemExpirationClaim(policyByClaim) || this.hasOpenAdvisorClaim(policyByClaim))) {
					this.completeClaim(policyByClaim!, event)

				} else if (isExcludedClaim || this.isExcludedFromPayoutProcess(policyByClaim!)) {
					const targetLink = "statusoverview.html"
					tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

					location.href = targetLink
				} else {
					const targetLink = "payoutprocess.html"
					tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

					location.href = targetLink
				}
			}
		}

		startExpiration(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)
			this.selectWidget("addExpiratie")

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		redeemPackageAccrualPolicy(policy: Policy) {
			this.selectPolicy(policy)
			this.selectWidget("addAfkoop")

			const infoText = `Uw verzekering maakt deel uit van een pakket. Dit pakket zorgt er voor dat uw levensverzekeringen één geheel vormen. U kunt deze overlijdensrisicoverzekering stopzetten nadat u de verzekering met polisnummer ${policy.policyIdentifier} heeft stopgezet. Regel <a href=${this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"}>hier</a> het stopzetten van uw verzekering met polisnummer ${policy.policyIdentifier}.`
			eventBus.emit("informationModal", "Stopzetten pakket van levensverzekeringen", "", infoText)
		}

		startCommute(policy: Policy, event: MouseEvent) {
			const activeRelatedAccrualPolicy = this.getActivePackageAccrualPolicy(policy)

			if (activeRelatedAccrualPolicy) {
				this.redeemPackageAccrualPolicy(activeRelatedAccrualPolicy)
			} else {
				this.selectPolicy(policy)
				this.selectWidget("addAfkoop")

				const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
				tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

				location.href = targetLink
			}
		}

		completeClaim(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)
			this.selectWidget("complete")

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		policyDocuments(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "documents.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		goToMyAccount(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, myAccountUrl)

			window.location.href = myAccountUrl
		}

		getActivePackageAccrualPolicy(item: Policy) {
			const isActivePackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy) && this.isRedeemable(policy)
					: false

			return this.policies.find(isActivePackageAccrualPolicy)
		}

		getRedeemedPackageAccrualPolicy(item: Policy) {
			const isRedeemedPackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy) && !this.isRedeemable(policy) &&
						this.isPackageAccrualPolicyRedeemed(policy)
					: false

			return this.policies.find(isRedeemedPackageAccrualPolicy)
		}

		hasExpiredPackageAccrualPolicy(item: Policy) {
			const isExpiredPackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy)
						&& (rules.hasExpirationClaim(policy) && !policy.claim?.hasBeenCancelled)
					: false

			return this.policies.some(isExpiredPackageAccrualPolicy)
		}

		hasOpenSystemExpirationClaim(item: Policy) {
			return rules.hasOpenSystemExpirationClaim(item)
		}

		isRedeemable(item: Policy) {
			return rules.redeemable(item)
		}

		canExpire(item: Policy) {
			return rules.canExpire(item)
		}

		isExpiring(item: Policy, period: 6 | 9) {
			return rules.expiresSoon(item, period)
		}

		isPledgedPolicy(item: Policy) {
			return rules.pledgedPolicy(item)
		}

		isLifeCourseSavingsScheme(item: Policy) {
			return rules.lifeCourseSavingsScheme(item)
		}

		isPending(item: Policy) {
			return rules.pendingClaim(item)
		}

		hasOpenOrPendingExpirationClaim(item: Policy) {
			return rules.hasOpenOrPendingExpirationClaim(item)
		}

		hasPendingExpirationClaim(item: Policy) {
			return rules.pendingClaim(item) && rules.hasExpirationClaim(item)
		}

		hasOpenOrPendingRedemptionClaim(item: Policy) {
			return rules.hasOpenOrPendingRedemptionClaim(item)
		}

		hasPendingRedemptionClaim(item: Policy) {
			return rules.pendingRedemptionClaim(item)
		}

		hasOpenAdvisorClaim(item: Policy) {
			return rules.hasOpenAdvisorClaim(item)
		}

		isPackageRiskPolicy(item: Policy) {
			return rules.packageRiskPolicy(item)
		}

		isPackageRiskPolicyToRedeem(item: Policy) {
			const redeemedRelatedAccrualPolicy = this.getRedeemedPackageAccrualPolicy(item)

			return redeemedRelatedAccrualPolicy ?
				rules.activePackageRiskPolicy(item) && !this.hasActiveClaim(item)
			: false
		}

		hasActiveClaim(item: Policy) {
			return item.claim && !item.claim.hasBeenCancelled
		}

		isPackageAccrualPolicyRedeemed(item: Policy) {
			return rules.isPackageAccrualPolicyRedeemed(item)
		}

		isPolicyEndedNoClaimYet(item: Policy) {
			return rules.policyEndedNoClaimYet(item)
		}

		isExcludedFromPayoutProcess(item: Policy) {
			return rules.isExcludedFromPayoutProcess(item)
		}

		changePolicyIsAllowed(item: Policy) {
			return rules.changePolicyAllowed(item)
		}

		documentDetail(document: CommunicationDocument, event: MouseEvent) {
			this.selectDocumentId(document.externalIdentifier)

			const targetLink = "communicationdetail.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink, "bekijk bericht")

			location.href = targetLink
		}

		toDocumentsPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		cleanPolicyNumber(policyNumber: string) {
			return policyNumber.includes("onderdeel") ? policyNumber.split(" ")[0] : policyNumber
		}

		claimCause(cause: string) {
			return cause === "Afkoop" ? "Voortijdig stopzetten" : cause
		}

		checkOnePolicy() {
			return this.policies.length === 1
		}

		checkOneClaim() {
			return this.claims.length === 1
		}

		async mounted() {
			document.title = "Uw levensverzekeringen | Overzicht"

			const script = document.createElement('script')
			script.type = "text/javascript"
			script.innerHTML = "var utag_data = { page_type: 'portal', site_business_line: 'leven', product_name: 'leven portaal'}"
            document.head.appendChild(script)
			
			await this.pollClaimChanged()
			this.selectPolicy(undefined)
			this.selectClaim(undefined)
			this.selectWidget(undefined)
			this.selectDocumentId("")
			this.setPayoutToPledgeHolderRequested(false)
			this.setAnnuityExpirationDestination(AnnuityExpirationDestination.UNDEFINED)
		}

		@Watch("pollClaim")
		async pollClaimChanged() {
			if (this.pollClaim.poll) {
				await this.pollClaimCommunication(this.pollClaim)
			}
		}
	}
</script>

<style>
	@import "main.css";
	@import "custom/insurance-table.css";
</style>
